import React , {useState } from 'react';
import  NavbarCustom  from '../../component/navabr/navbarCustom';
import LogoutCustom from '../logout/logout';
import { TextField } from "@material-ui/core";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { AdminCompetitonSearch,AdminCompetitonSearch2,AdminCheckUserStatus,AdminCheckUserStatus2 } from "../../redux/action/action";
import { connect } from "react-redux";
import {
  ClientId,
  SecretId,
  API_Prefix
} from "../config/config";
let server_url = `${API_Prefix}/o/`;
let data = `grant_type=client_credentials&client_id=${ClientId}&client_secret=${SecretId}`;
let accessToken = null;

const CompetitionScreen = (props) => {

    const [getusername, setusername] = useState("");
    const [getusernameplayer2, setusernameplayer2] = useState("");
    const [getGameAttendeeIdPlayer,setGameAttendeeIdPlayer] = useState(null);
    const [getGameAttendeeIdPlayer2,setGameAttendeeIdPlayer2] = useState(null);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const getUsers = props && props.adminSearchUser && props.adminSearchUser.data && props.adminSearchUser.data.users || "";
    const getUsers2 = props && props.adminSearchUser2 && props.adminSearchUser2.data && props.adminSearchUser2.data.users || "";
    const [selectedRow, setSelectedRow] = React.useState(-1);
    const [selectedRow2, setSelectedRow2] = React.useState(-1);
    const handleClose1 = () => setShow1(false);
    const handleClose2 = () => setShow2(false);
    const handleShow1 = () => setShow1(true);
    const [state, setstate] = useState({ name: "", email: "" });
    const navigate = useNavigate();

    if(props && props.adminSearchUser && props.adminSearchUser.data && props.adminSearchUser.data.status === "Error")
    {
      navigate({ pathname: "/admin" });
    }
    if(props && props.adminSearchUser2 && props.adminSearchUser2.data && props.adminSearchUser2.data.status === "Error")
    {
      navigate({ pathname: "/admin" });
    }
    
    React.useEffect(() => {
      // POST request to get Token using fetch()
      fetch(server_url + "oauth2/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          accessToken = json.access_token;
        });
    }, []);

    React.useEffect(() => {
      const interval = setInterval(() => {
        if(getGameAttendeeIdPlayer!== null)
        {
        props.AdminCheckUserStatus(getGameAttendeeIdPlayer,accessToken);
        }
      }, 5000);
      return () => clearInterval(interval);
    }, [getGameAttendeeIdPlayer]);

    React.useEffect(() => {
      const interval = setInterval(() => {
        if(getGameAttendeeIdPlayer2 !==null)
        {
        props.AdminCheckUserStatus2(getGameAttendeeIdPlayer2,accessToken);
        }
      }, 5000);
      return () => clearInterval(interval);
    }, [getGameAttendeeIdPlayer2]);

    const handleShow2 = () => 
    {
      setShow2(true);
    }
    const handleNavigation = async (e) => {
      e.preventDefault();
      if (state.name !== "" && state.email !== "") {
        props.AdminCompetitonSearch(state.name, state.email, accessToken);
    }  
  }
  const handleNavigation2 = async (e) => {
    e.preventDefault();
    if (state.name !== "" && state.email !== "") {
      props.AdminCompetitonSearch2(state.name, state.email, accessToken);
  }  
}
  function handleChange(evt) {
    const value = evt.target.value;
    setstate({
      ...state,
      [evt.target.name]: value
    });
  }

  const handleRow =(username, index, prGameAttendeeId)=>{
    setGameAttendeeIdPlayer(prGameAttendeeId);
    setSelectedRow(index);
    setusername(username);
  }
  const handleRowPlayer2 =(username, index,prGameAttendeeId)=>{
    setGameAttendeeIdPlayer2(prGameAttendeeId);
    setSelectedRow2(index);
    setusernameplayer2(username);
  }

  const handleLogout = ()=>{
    navigate({ pathname: "/admin" });
  }

  return (
   <>
   <div className='container-fluid admin-screen'>
        <div className='row d-flex'> 
        <div className='w-18'>
        <NavbarCustom/>
        </div>
            <div className='w-35' style={{display:"-webkit-inline-box"}}>
            <p className='player-text-1 mt-4 p-1'>
            <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/blue-gif-2.gif' className='players-icon-top mx-1' />
            {getusername!=="" ? getusername : "Player 1"}
            </p>
            <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/icons-03.png' className='menu-icon mt-3 mx-3' title='Search' onClick={handleShow1}/>
            <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
          <Modal.Title>Player 1 Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="pb-4" >
              {/* <Form.Label>Player Name</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Player Name"
                name="name"
                value={state.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="pb-4" >
              {/* <Form.Label>Email address</Form.Label> */}
              <Form.Control
                type="email"
                name="email"
                placeholder="Player Email"
                value={state.email}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
          <Modal.Footer>
          <Button className='custom-btn' onClick={(e) => handleNavigation(e)}>
            Search
          </Button>
        </Modal.Footer> 

          <div class="container">
          <div class="row">
            <div class="col-12">
              <table class="table table-bordered">
                <thead>
            <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          {getUsers && getUsers.length > 0 ? (
           getUsers && getUsers.map((result, index) => (
          <tr className={"clickable-row ".concat(selectedRow === index ? "selected" : "")} key={index} onClick={() => handleRow(result.name, index, result.prGameAttendeeId)}>
            <td>{result.name}</td>
            <td>{result.email}</td>
        
          </tr>
           ))
          ):(
          "No User found"
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>

        </Modal.Body>
        {/* <Modal.Footer> */}
          {/* <Button className='custom-btn' onClick={handleClose1}>
            Close
          </Button> */}
          {/* <Button className='custom-btn' onClick={(e) => handleNavigation(e)}>
            Save
          </Button>
        </Modal.Footer>  */}
      </Modal>
                
            </div>
            <div className='w-35' style={{display:"-webkit-inline-box"}}>
            <p className='player-text-1 mt-4 p-1'> <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/red-gif.gif' className='players-icon-top mx-1' />
            {getusernameplayer2!=="" ? getusernameplayer2 : "Player 2"}
            </p>
            <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/icons-03.png' className='menu-icon mt-3 mx-3' title='Search' onClick={handleShow2}/>
            <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Player 2 Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Form.Group className="pb-4" >
              {/* <Form.Label>Player Name</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Player Name"
                name="name"
                value={state.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="pb-4" >
              {/* <Form.Label>Email address</Form.Label> */}
              <Form.Control
                type="email"
                name="email"
                placeholder="Player Email"
                value={state.email}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
          <Modal.Footer>
          <Button className='custom-btn' onClick={(e) => handleNavigation2(e)}>
            Search
          </Button>
        </Modal.Footer> 
          <div class="container">
          <div class="row">
            <div class="col-12">
              <table class="table table-bordered">
                <thead>
            <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          {getUsers2 && getUsers2.length > 0 ? (
           getUsers2 && getUsers2.map((result, index) => (
          <tr className={"clickable-row ".concat(selectedRow2 === index ? "selected" : "")} key={index} onClick={() => handleRowPlayer2(result.name, index, result.prGameAttendeeId)}>
            <td>{result.name}</td>
            <td>{result.email}</td>
        
          </tr>
           ))
          ):(
          "No User found"
          )}
        </tbody>
      </table>
    </div>
  </div>
</div>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button className='custom-btn' onClick={handleClose2}>
            Close
          </Button>
          <Button className='custom-btn' onClick={handleClose2}>
            Save
          </Button>
        </Modal.Footer> */}
      </Modal>
            </div>
            <div className='w-12 text-end' onClick = {handleLogout}>
            <LogoutCustom/>
            </div>
        </div>
        <div className='row player-position-start'>
        <div className='col-md-12'>
        {props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
         props.checkstatus.data.currentModuleName === "MALWARE" ?
        <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/blue-gif-2.gif' className='malware-players-icon' />
        :
        props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
         props.checkstatus.data.currentModuleName === "RANSOMEWARE" ?
         <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/blue-gif-2.gif' className='ransomeware-players-icon' />
         :
         props && props.checkstatus && props.checkstatus.data && props.checkstatus.data.currentModuleName &&
         props.checkstatus.data.currentModuleName === "SOCIAL ENGINEERING" ?
         <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/blue-gif-2.gif' className='social-players-icon' />
         :
        <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/blue-gif-2.gif' className='players-icon' />
        }


        {props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
         props.checkstatus2.data.currentModuleName === "MALWARE" ?
         <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/red-gif.gif' className='malware-players-icon-red' />
        :
         props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
         props.checkstatus2.data.currentModuleName === "RANSOMEWARE" ?
         <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/red-gif.gif' className='ransomeware-players-icon-red' />
         :
         props && props.checkstatus2 && props.checkstatus2.data && props.checkstatus2.data.currentModuleName &&
         props.checkstatus2.data.currentModuleName === "SOCIAL ENGINEERING" ?
         <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/red-gif.gif' className='social-players-icon-red' />
         :

        <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/red-gif.gif' className='players-icon mt-1 mx-1' />
        }
        </div>
        </div>
   </div>
   </>
  )
}
const mapStateToProps = (state)=>{
  return{
      adminSearchUser : state.GameReducer.adminSearchUser,
      adminSearchUser2 : state.GameReducer.adminSearchUser2,
      checkstatus: state.GameReducer.checkstatus,
      checkstatus2: state.GameReducer.checkstatus2
  }
}
export default connect(mapStateToProps, { AdminCompetitonSearch,AdminCompetitonSearch2,AdminCheckUserStatus,AdminCheckUserStatus2 })(CompetitionScreen);
