export const LOGIN = "LOGIN";
export const LOGIN_CREDENTAILS = "LOGIN_CREDENTAILS";
export const ADMIN_LOGIN="ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const SEARCH_USER ="SEARCH_USER";
export const SEARCH_USER_PLAYER2 ="SEARCH_USER_PLAYER2";
export const ADMIN_CHECK_STATUS ="ADMIN_CHECK_STATUS";
export const ADMIN_CHECK_STATUS_SECOND ="ADMIN_CHECK_STATUS_SECOND";
export const TOP_USERS_OF_DAY ="TOP_USERS_OF_DAY";
export const URL_PARAMS = "URL_PARAMS";
export const RESULT = "RESULT";
export const PHISHINGMODULE = "PHISHINGMODULE";
export const MALWAREMODULE = "MALWAREMODULE";
export const RANSOMEWAREMODULE = "RANSOMEWAREMODULE";
export const INSIDERTHREAT = "INSIDERTHREAT";
export const SOCIALENGINEERINGMODULE = "SOCIALENGINEERINGMODULE";
export const DENIALSERVICE = "DENIALSERVICE";