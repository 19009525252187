import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GameResult } from "../../redux/action/action";
// import Map from "../Rander/resultmap.png";

const Result = (props) => {
  const [map, setmap] = useState(false);
  const [showResult, setResult] = useState(null);
  let result = null;

  const saveResult  = props.urlloginresponseresult;
  const denaildetour =
    (props.denialScore &&
      props.denialScore.setDenail &&
      props.denialScore.setDenail.denailservicedetourcount) ||
    0;
  const malwaredetour =
    (props.malwareScore &&
      props.malwareScore.setMalware &&
      props.malwareScore.setMalware.malwaredetourcount) ||
    0;
  const phishingdetour =
    (props.phishingScore &&
      props.phishingScore.setPhishing &&
      props.phishingScore.setPhishing.phishingdetourcount) ||
    0;
  const ransomewaredetour =
    (props.ransomewareScore &&
      props.ransomewareScore.setRansomeware &&
      props.ransomewareScore.setRansomeware.ransomewaredetourcount) ||
    0;
  const insiderdetour =
    (props.insiderthreatScore &&
      props.insiderthreatScore.setInsider &&
      props.insiderthreatScore.setInsider.insiderthreatdetourcount) ||
    0;
  const socialdetour =
    (props.socialScore &&
      props.socialScore.setSocial &&
      props.socialScore.setSocial.socialengineeringdetourcount) ||
    0;

  const scorePhishing =
    props.phishingScore &&
    props.phishingScore.setPhishing &&
    props.phishingScore.setPhishing.score
      ? props.phishingScore &&
        props.phishingScore.setPhishing &&
        props.phishingScore.setPhishing.score
      : null;
  const phishingPercent = (scorePhishing / 3) * 100;

  const scoreMalware =
    props.malwareScore &&
    props.malwareScore.setMalware &&
    props.malwareScore.setMalware.score
      ? props.malwareScore &&
        props.malwareScore.setMalware &&
        props.malwareScore.setMalware.score
      : null;
  const malwarePercent = (scoreMalware / 3) * 100;

  const scoreRansomeware =
    props.ransomewareScore &&
    props.ransomewareScore.setRansomeware &&
    props.ransomewareScore.setRansomeware.score
      ? props.ransomewareScore &&
        props.ransomewareScore.setRansomeware &&
        props.ransomewareScore.setRansomeware.score
      : null;
  const ransomewarePercent = (scoreRansomeware / 3) * 100;

  const scoreSocial =
    props.socialScore &&
    props.socialScore.setSocial &&
    props.socialScore.setSocial.score
      ? props.socialScore &&
        props.socialScore.setSocial &&
        props.socialScore.setSocial.score
      : null;
  const SocialPercent = (scoreSocial / 3) * 100;

  const scoreInsider =
    props.insiderthreatScore &&
    props.insiderthreatScore.setInsider &&
    props.insiderthreatScore.setInsider.score
      ? props.insiderthreatScore &&
        props.insiderthreatScore.setInsider &&
        props.insiderthreatScore.setInsider.score
      : null;
  const insiderPercent = (scoreInsider / 3) * 100;

  const scoreDenial =
    props.denialScore &&
    props.denialScore.setDenail &&
    props.denialScore.setDenail.score
      ? props.denialScore &&
        props.denialScore.setDenail &&
        props.denialScore.setDenail.score
      : null;
  const DenialPercent = (scoreDenial / 3) * 100;


  useEffect(() => {
    result = {
      prGameAttendeeId:
        (props.prGameAttendeeId &&
          props.prGameAttendeeId.data &&
          props.prGameAttendeeId.data.prGameAttendeeId) ||
        null,
        prGameAttendeeAttemptId:(props.prGameAttendeeAttemptId &&
          props.prGameAttendeeAttemptId.data &&
          props.prGameAttendeeAttemptId.data.prGameAttendeeAttemptId) || null,
        moduleResults:
      // moduleResults: [
      //   {
      //     moduleName: "PHISHING",
      //     totalQuestions: 3,
      //     correctAnswers: scorePhishing,
      //     scorePercent: phishingPercent,
      //   },
      //   {
      //     moduleName: "MALWARE",
      //     totalQuestions: 3,
      //     correctAnswers: scoreMalware,
      //     scorePercent: malwarePercent,
      //   },
      //   {
      //     moduleName: "RANSOMEWARE",
      //     totalQuestions: 3,
      //     correctAnswers: scoreRansomeware,
      //     scorePercent: ransomewarePercent,
      //   },
      //   {
      //     moduleName: "INSIDER THREAT BAY",
      //     totalQuestions: 3,
      //     correctAnswers: scoreInsider,
      //     scorePercent: insiderPercent,
      //   },
        {
          moduleName: "SOCIAL ENGINEERING",
          totalQuestions: 3,
          correctAnswers: scoreSocial,
          scorePercent: SocialPercent,
        },
        // {
        //   moduleName: "DENIAL SERVICE",
        //   totalQuestions: 3,
        //   correctAnswers: 3,
        //   scorePercent: "100",
        // },
      // ],
      
      // accountUserId: saveResult.accountUserId,
      // gameUserId: saveResult.gameUserId
    };
    
    props.GameResult(result);
    setResult(result);
  }, [result]);

  const Result = () => {
    setmap(true);
  };
  return (
    <div className="container-fluid p-0 cyber-island">
      {map === true ? (
        <>
          <img
            className="result-map"
            // src="https://dqib2w4u3w0ne.cloudfront.net/Rander/resultmap.png"
            src= "https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/resultmap-f1.png"
            alt="Logo"
          />
          {/* <div className="denail-result-leager">
            {denaildetour === 0 ? (
              <>
                <span className="lead fw-normal mb-0 me-3 result-denail-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-denail-detour-green">
                  <b>{denaildetour})</b>
                </span>
              </>
            ) : (
              <>
                <span className="lead fw-normal mb-0 me-3 result-denail-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-denail-detour">
                  <b>{denaildetour})</b>
                </span>
              </>
            )}
          </div> */}

          <div className="malware-result-leager">
            {malwaredetour === 0 ? (
              <>
                <span className="lead fw-normal mb-0 me-3 result-denail-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-malware-detour-green">
                  <b>{malwaredetour})</b>
                </span>
              </>
            ) : (
              <>
                <span className="lead fw-normal mb-0 me-3 result-malware-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-malware-detour">
                  <b>{malwaredetour})</b>
                </span>
              </>
            )}
          </div>

          <div className="phishing-result-leager">
            {phishingdetour === 0 ? (
              <>
                <span className="lead fw-normal mb-0 me-3 result-phishing-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-phishing-detour-green">
                  <b>{phishingdetour})</b>
                </span>
              </>
            ) : (
              <>
                <span className="lead fw-normal mb-0 me-3 result-phishing-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-phishing-detour">
                  <b>{phishingdetour})</b>
                </span>
              </>
            )}
          </div>

          <div className="ransomeware-result-leager">
            {ransomewaredetour === 0 ? (
              <>
                <span className="lead fw-normal mb-0 me-3 result-ransomeware-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-ransomeware-detour-green">
                  <b>{ransomewaredetour})</b>
                </span>
              </>
            ) : (
              <>
                <span className="lead fw-normal mb-0 me-3 result-ransomeware-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-ransomeware-detour">
                  <b>{ransomewaredetour})</b>
                </span>
              </>
            )}
          </div>

          {/* <div className="insider-result-leager">
            {insiderdetour === 0 ? (
              <>
                <span className="lead fw-normal mb-0 me-3 result-insider-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-insider-detour-green">
                  <b>{insiderdetour})</b>
                </span>
              </>
            ) : (
              <>
                <span className="lead fw-normal mb-0 me-3 result-insider-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-insider-detour">
                  <b>{insiderdetour})</b>
                </span>
              </>
            )}
          </div> */}

          <div className="social-result-leager">
            {socialdetour === 0 ? (
              <>
                <span className="lead fw-normal mb-0 me-3 result-social-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-social-detour-green">
                  <b>{socialdetour})</b>
                </span>
              </>
            ) : (
              <>
                <span className="lead fw-normal mb-0 me-3 result-social-percentage">
                  <b>({100}% , </b>
                </span>
                <span className="lead fw-normal mb-0 me-3 result-social-detour">
                  <b>{socialdetour})</b>
                </span>
              </>
            )}
          </div>
        </>
      ) : (
        <button className="result-button" onClick={Result}>
          Result
        </button>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    phishingScore: state.GameReducer.phishingScore,
    prGameAttendeeId: state.GameReducer.login,
    prGameAttendeeAttemptId: state.GameReducer.login,
    malwareScore: state.GameReducer.malwareScore,
    ransomewareScore: state.GameReducer.ransomewareScore,
    insiderthreatScore: state.GameReducer.insiderthreatScore,
    socialScore: state.GameReducer.socialScore,
    denialScore: state.GameReducer.denialScore,
    urlloginresponseresult:state.GameReducer.urlloginresponseresult
  };
};
export default connect(mapStateToProps, { GameResult })(Result);
