export function Data() {
    return [
            {
                "category": "Science Computers",
                 "correct_answer": "Do not download or open the file and report the email immediately",
                 "difficulty": "easy",
                 "incorrect_answers": [
                        "Download the attachment and open it to see what it contains",
                        "Forward the email to your friends or colleagues to get their opinions",
                        "Delete the email immediately to be safe"
                ],
                "question": "What should you do if you receive a suspicious email with an attachment?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Researching their intended victims on social media sites",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Getting a job at the target organization",
                    "Hacking the target organization directly",
                    "Avoiding interaction with company employees"
                ],
                "question": "To make social engineering attacks successful, attackers often prepare by:",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "All",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Avoid disclosing personal information in public places",
                    "For critical information speak to authorized people only.",
                    "Do not post work-related information on social media sites"
                ],
                "question": "What are some techniques to avoid social engineering?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Shoulder Surfing",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Clear Desk Clear Screen",
                    "Using strong passwords",
                    "Turning-off auto connect to Wi-Fi"
                ],
                "question": "Which one of these is a type of social engineering?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "All",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Date of birth",
                    "Your identity",
                    "Your likes & dislikes"
                ],
                "question": "Hackers can attain which of the following things from your social media handles?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Mobile Security",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Phishing",
                    "Impersonation",
                    "Shoulder Surfing"
                    
                ],
                "question": "Which of these is not a Social Engineering tactic?",
                 "type": "multiple"
            },
        ]
}