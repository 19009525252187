import React from 'react';
import {useNavigate } from "react-router-dom";
// import Image from "../Rander/Cyber-island-screen.gif";
// import PhishingMap from "../Rander/map.gif";
// "https://d1pq0eh5wwswp1.cloudfront.net/Rander/map.gif"

const Map = () => {

const navigate = useNavigate();

const handleNavigation = () => {
  navigate({pathname:"/phishingtoolkit",
});

}
  return (
  <div className="container-fluid p-0 phishing-module">
  <div className="d-flex vh-100">
    <div className="splash">
      {/* <img src={Image} alt="Logo" /> */}
      <button className="splash-navigation-map-btn">
        Navigation Map
      </button>
      <button className="splash-toolkit-btn" onClick={() => handleNavigation()}>
        Clue
      </button>
      <img className='phishing-map' src="https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/navigation-map-f1.gif" alt="Logo" />
    </div>
  </div>
</div>
  )
}
export default Map
