import './App.css';
import Loginform from './component/quizgame/loginform';
import AdminLogin from './component/admin/adminLogin';
import Question from './component/quizgame/question';
import RansomewareQuestion from './component/ransomwaremodule/ransomewareQuestion';
import SocialEngineeringQuestion from './component/socialengineeringmodule/socialEngineeringQuestion';
import PhishingModule from './component/phishingmodule/phishingModule';
import MalwareModule from "./component/malwaremodule/malwareModule";
import RansomwareModule from "./component/ransomwaremodule/ransomwareModule";
import SocialEngineeringModule from "./component/socialengineeringmodule/socialEngineeringModule";
// import DenailServiceModule from "./component/denialservice/denailserviceModule";
import MalwareQuestion from "./component/malwaremodule/malwareQuestion";
// import InsiderThreatQuestion from "./component/insiderthreat/insiderthreatQuestion";
// import InsiderThreatModule from "./component/insiderthreat/insiderthreatModule";
import Map from "./component/map/map";
import Toolkit from "../src/component/phishingmodule/toolkit";
import MalwareToolkit from "../src/component/malwaremodule/toolkit";
import RansomewareToolkit from "../src/component/ransomwaremodule/toolkit";
import SocialEngineeringToolkit from "../src/component/socialengineeringmodule/toolkit";
import InsiderToolkit from "../src/component/insiderthreat/toolkit";
// import DenialServiceToolkit from "../src/component/denialservice/toolkit";
// import DenialServiceQuestion from "../src/component/denialservice/denialserviceQuestion";
import CyberIsland from "../src/component/quizgame/cyberIsland";
import {
  BrowserRouter as Router, Routes, Route,
} from "react-router-dom";
import SplashScreen from './component/quizgame/splashScreen';
import  Result  from './component/modulesresult/result';
import CompetitionScreen from './component/competitionscreen/competitionScreen';
import LeaderBoardScreen from './component/leaderboardscreen/leaderBoardScreen';

function App() {
  return (
    <>
       <Router>
        <Routes>
          <Route exact path="/" element ={<SplashScreen/>}/>
          <Route path="/login" element={<Loginform />} />
          <Route path="/admin" element={<AdminLogin/>}/>
          <Route path="/competition" element={<CompetitionScreen/>}/>
          <Route path="/leaderboard" element={<LeaderBoardScreen/>}/>
          <Route path="/question" element={<Question />} />
          <Route path="/ransomewareQuestion" element={<RansomewareQuestion/>}/>
          <Route path="/malwareQuestion" element={<MalwareQuestion/>}/>
          <Route path="/socialengineeringQuestion" element={<SocialEngineeringQuestion/>}/>
          <Route path='/phishingmodule' element={<PhishingModule/>}/>
          <Route path='/socialengineeringmodule' element={<SocialEngineeringModule/>}/>
          {/* <Route path='/denailservicemodule' element={<DenailServiceModule/>}/> */}
          <Route path="/phishingmap" element ={<Map/>}/>
          <Route path='/ransomwaremodule' element={<RansomwareModule/>}/> 
          <Route path='/malwaremodule' element={<MalwareModule/>}/> 

          {/* <Route path='/insiderthreatQuestion' element={<InsiderThreatQuestion/>}/>
          <Route path='/insiderthreatmodule' element={<InsiderThreatModule/>}/>  */}
          
          <Route path='/phishingtoolkit' element={<Toolkit/>}/>
          <Route path='/malwaretoolkit' element={<MalwareToolkit/>}/>
          <Route path='/ransomewaretoolkit' element={<RansomewareToolkit/>}/>
          <Route path='/socialengineeringtoolkit' element={<SocialEngineeringToolkit/>}/>
          <Route path='/insidertoolkit' element={<InsiderToolkit/>}/>
{/* 
          <Route path='/denialserviceQuestion' element={<DenialServiceQuestion/>}/> */}
          {/* <Route path='/denialtoolkit' element={<DenialServiceToolkit/>}/> */}
          <Route path='/cyberIsland' element={<CyberIsland/>}/>


          <Route path='/result' element={<Result/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
