export function Data() {
    return [
            {
                "category": "Science Computers",
                 "correct_answer": "A type of malware that infects devices to hold your data hostage until you send the attackers money.",
                 "difficulty": "easy",
                 "incorrect_answers": [
                        "Computer equipment that criminals steal from you and won’t return until you pay them.",
                        "Software used to protect your computer or mobile device from harmful viruses.",
                        "A form of cryptocurrency",
                ],
                "question": "What is Ransomware?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "False",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "True",
                ],
                "question": "If you encounter a ransomware attack, the first thing you should do is pay the ransom.",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Restore system from backup",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Delete the ransomware from your system",
                    "Email the hackers to obtain the decryption key",
                    "Use a decryption tool to access affected files"
                ],
                "question": "What can you do if you fall victim to a ransomware attack?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Encrypting important files",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Moving Operating System data",
                    "Deleting key system data",
                    "Copying a user’s personal data"
                ],
                "question": "What process does a ransomware hacker perform on a user's system?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "All the Above",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Sending a scam email with links or attachments",
                    "Getting into your server through vulnerabilities and installing malware.",
                    "Using infected websites that automatically download malicious software"
                ],
                "question": "Which of the following best describe how criminals start ransomware attacks?",
                 "type": "multiple"
            },
            {
                "category": "Science Computers",
                 "correct_answer": "Regularly backup important data",
                 "difficulty": "easy",
                 "incorrect_answers": [
                    "Avoid using emails for communication",
                    "Keep data in a single disk drive",
                    "Password protect files and folders"
                ],
                "question": "What is one recommended practice to avoid ransomware threats?",
                 "type": "multiple"
            }
        ]
}