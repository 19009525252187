import React from 'react';
import {useNavigate } from "react-router-dom";

const CyberIsland = () => {

const navigate = useNavigate();

const handleNavigation = () => {
   navigate({pathname:"/phishingmodule"});
}
  return (
    <div className="container-fluid p-0 cyberIsland-bg">
     <button onClick = {handleNavigation} className="next-module">Next</button> 
  </div>
  )
}
export default CyberIsland;
