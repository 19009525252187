import React from 'react';
import {useNavigate } from "react-router-dom";
// import Image from "../Rander/Cyber-island-screen.gif";

const PhishingModule = () => {

const navigate = useNavigate();

const handleNavigation = () => {
  navigate({pathname:"/phishingmap",
});
}
  return (
  <div className="container-fluid p-0 phishing-module">
  <div className="d-flex vh-100">
    <div className="splash">
      {/* <img src={Image} alt="Logo" />  */}
      <button className="splash-navigation-btn" onClick={() => handleNavigation()}>
        Navigation Map
      </button>
      <button className="splash-tool-btn">
       Clue
      </button>
    </div>
  </div>
</div>
  )
}
export default PhishingModule
