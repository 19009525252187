import React,{ useState } from 'react';
import  NavbarCustom  from '../../component/navabr/navbarCustom';
import LogoutCustom from '../logout/logout';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {TopUsersOfDay} from "../../redux/action/action";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import {

  ClientId,

  SecretId,

  API_Prefix

} from "../config/config";

let server_url = `${API_Prefix}/o/`;

let data = `grant_type=client_credentials&client_id=${ClientId}&client_secret=${SecretId}`;

let accessToken = null;


const LeaderBoardScreen = (props) =>  {

  const topusers = props && props.topusers && props.topusers.data && props.topusers.data.users || null; 
  const [email, setEmail] = useState("");
  const [state, setstate] = useState({email: "" });
  const [show3, setShow3] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();


  React.useEffect(() => {

    // POST request to get Token using fetch()

    fetch(server_url + "oauth2/token", {

      method: "POST",

      headers: {

        "Content-Type": "application/x-www-form-urlencoded",

      },

      body: data,

    })
      .then((response) => response.json())
      .then((json) => {
        accessToken = json.access_token;
      }
      );
  }, []);

  React.useEffect(()=>{
    const interval = setInterval(() => {
      props.TopUsersOfDay(email,accessToken);
    }, 5000);
    return () => clearInterval(interval);
  
  },[email]);

  const handleShow3 = () => {
    setShow1(true);
    setShow3(true);
  }

  const handleClose1 = () => setShow1(false);


  const handleNavigation = async (e) => {
    e.preventDefault();  
    setEmail(state.email);
    props.TopUsersOfDay(state.email,accessToken);
}

  function handleChange(evt) {
    const value = evt.target.value;
    setstate({
      ...state,
      [evt.target.name]: value
    });
  }

  const handleLogout = ()=>{
    navigate({ pathname: "/admin" });
  }
  return (
    <>

    <div className='leaderboard-bg'>

    <div className='row'> 
        <div className='col-md-6'>
        <div className='leaderboard-menu-icon'>
        <NavbarCustom/>
        </div>
        </div>

           <div className='col-md-6 text-end'>
           <a onClick={handleShow3}>
            <img src='https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/icons-02.png' className='menu-icon mt-3 mx-' title='Settings'/>
          </a>
          <a onClick={handleLogout}>
            <LogoutCustom/>
            </a>
            </div>
        </div>

        <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
          <Modal.Title>Domain Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="pb-4" >
              {/* <Form.Label>Player Name</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Domain Name"
                name="email"
                value={state.email}
                onChange={handleChange}
              />
            </Form.Group>
            </Form>
            <Modal.Footer>
          <Button className='custom-btn' onClick={(e) => handleNavigation(e)}>
            Search
          </Button>
        </Modal.Footer> 
            </Modal.Body>
        </Modal>

    <div className="center center-bg">

    <div className='leaderboard-text-img'>

     <img className="leaderboard-img" src={"https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/leaderboard-dashboard-assets-f1.png"}/>

     </div>
    
    <div className='winners'>

     <div className='row'>

      <div className='col-md-12'>

      <div class="container">

          <div class="row">

            <div class="col-12">

              <table class="table table-bordered mt-5 text-center">

                <thead>

            <tr className='heading-bg'>

              <th scope="col">Rank</th>

            <th scope="col">Player Name</th>

            <th scope="col">Modules</th>

            <th scope="col">Time</th>

          </tr>
        </thead>

      {topusers && topusers.length > 0 ? (
       topusers && topusers.map((result, index) => ( 
        <tbody>
       {index !== 5 &&
       <>
        {index === 0 &&
        <>
        <tr class="separator" />
          <tr key={index}>
             
            <td className='w-17'><img className='winner-04' src={"https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/leaderboard-table-assets-f1.png"}/><img className='winner-04' src={"https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/leaderboard-assets-04.png"}/></td>

            <td className='result-bg left-side'>{result.name}</td>

            <td className='result-bg'>{result.stagesCompleted}</td>

            <td className='result-bg right-side'>{result.totalTimeSpent} sec</td>
          
          </tr>
          </>
        }
        {index === 1 &&
           <>
          <tr class="separator" />
          <tr key={index}>
             
            <td className='w-17'><img className='winner-04' src={"https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/leaderboard-table-assets-f2.png"}/><img className='winner-04' src={"https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/leaderboard-assets-04.png"}/></td>

            <td className='result-bg left-side'>{result.name}</td>

            <td className='result-bg'>{result.stagesCompleted}</td>

            <td className='result-bg right-side'>{result.totalTimeSpent} sec</td>
          
          </tr>
          </>
          }
          {index === 2 &&
           <>
          <tr class="separator" />
          <tr key={index}>
             
            <td className='w-17'><img className='winner-04' src={"https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/leaderboard-table-assets-f3.png"}/><img className='winner-04' src={"https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/leaderboard-assets-04.png"}/></td>

            <td className='result-bg left-side'>{result.name}</td>

            <td className='result-bg'>{result.stagesCompleted}</td>

            <td className='result-bg right-side'>{result.totalTimeSpent} sec</td>
          
          </tr>
          </>
          }
          {index === 3 || index === 4 && 
          <>
          <tr class="separator" />
        
          <tr>
          
          <td className='w-17'><span className='winner-04 d-inline-block fw-bold text-center fs-20'>{index + 1}</span><img className='winner-04' src={"https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/leaderboard-assets-04.png"}/></td>
          
          <td className='result-bg left-side'>{result.name}</td>

          <td className='result-bg'>{result.stagesCompleted}</td>

          <td className='result-bg right-side'>{result.totalTimeSpent} sec</td>
            
          </tr>
          </>
          }
       
          {/* {index === 4 &&
          <tr>

          <td className='w-17'><span className='winner-04 d-inline-block fw-bold text-center fs-20'>5</span><img className='winner-04' src={"https://phishrod-game.s3.us-east-2.amazonaws.com/Rander/GameAdmin/leaderboard-assets-04.png"}/></td>

          <td className='result-bg left-side'>{result.name}</td>

          <td className='result-bg'>{result.stagesCompleted}</td>

          <td className='result-bg right-side'>{result.totalTimeSpent}</td>

          </tr>
          } */}
          </>
          }
          </tbody>
          )))
          :
          "No User found"
        }
      </table>

      </div>

      </div>

    </div>

      </div>

     </div>

     </div>
      {/* ))): "Record not found"} */}
    </div>
      
   

     </div>

     </>

  );

}
const mapStateToProps =(state)=>{
  return{
    topusers: state.GameReducer.topusers
  }
}
export default connect(mapStateToProps, {TopUsersOfDay})(LeaderBoardScreen);