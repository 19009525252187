import React from "react";
import Spinner from "react-spinners/HashLoader";

const Spiner = (props) => {

  const color =  "#17a2b8";
  return (
  <Spinner size={100} color={color} timeout={3000}  />
  )
};
export default Spiner;
